import {
  EMPTY,
  Observable,
  ObservableInput,
  ObservedValueOf,
  OperatorFunction,
} from 'rxjs';
import { catchError, map, startWith, tap } from 'rxjs/operators';
import { ApplicationResult } from '../api-client';
import { plainToInstance } from 'class-transformer';
import { ClassConstructor } from 'class-transformer/types/interfaces';
import { LoadingModel } from '@core/models/loading-model';
import { startsWith } from 'lodash-es';
import { inject } from '@angular/core';
import { UiNotificationService } from '@/ui-notifications/ui-notification.service';

export function formatErrors<T>() {
  return function (source: Observable<T>) {
    return source.pipe(
      catchError((err) => {
        if (err.response) {
          throw JSON.parse(err.response);
        } else {
          throw err;
        }
      }),
    );
  };
}

export function unwrap<T>() {
  return function (source: Observable<ApplicationResult>): Observable<T> {
    return source.pipe(
      formatErrors(),
      map((response) => {
        if (!response.succeed) {
          throw response.errors;
        }
        return (response as any).data;
      }),
    );
  };
}

export function withLoading<T>() {
  return function (source: Observable<T>): Observable<LoadingModel<T>> {
    return source.pipe(
      map((data) => ({ loading: false, data }) as const),
      startWith({ loading: true, data: undefined } as const),
    );
  };
}

export function unwrapMappedArray<T>(csl: ClassConstructor<T>) {
  return function (source: Observable<ApplicationResult>): Observable<T[]> {
    return source.pipe(
      formatErrors(),
      map((response) => {
        if (!response.succeed) {
          throw response.errors;
        }
        return plainToInstance(csl, (response as any).data as unknown[]);
      }),
    );
  };
}

export function unwrapMapped<T>(csl: ClassConstructor<T>) {
  return function (source: Observable<ApplicationResult>): Observable<T> {
    return source.pipe(
      formatErrors(),
      map((response) => {
        if (!response.succeed) {
          throw response.errors;
        }
        return plainToInstance(csl, (response as any).data);
      }),
    );
  };
}

export function isApplicationError(err: any) {
  return Array.isArray(err);
}

export function onApplicationError<T>(
  handle?: (err: any, caught: Observable<T>) => void,
): OperatorFunction<T, T | ObservedValueOf<Observable<never>>> {
  return catchError<T, Observable<never>>((err, caught) => {
    if (!isApplicationError(err)) {
      throw err;
    }
    if (handle) {
      handle(err[0], caught);
    }
    return EMPTY;
  });
}

export function onValidationError<T>(
  handle?: (err: any, caught: Observable<T>) => void,
): OperatorFunction<T, T | ObservedValueOf<Observable<never>>> {
  return catchError<T, Observable<never>>((err, caught) => {
    const errors: any = {};
    Object.keys(err.errors).forEach((key) => {
      errors[key] = err.errors[key][0];
    });
    if (handle) {
      handle(errors, caught);
    }
    return EMPTY;
  });
}

export function onError<T, O extends ObservableInput<any>>(
  handle?: (err: any, caught: Observable<T>) => O | void,
): OperatorFunction<T, T | ObservedValueOf<Observable<never>>> {
  return catchError<T, Observable<never> | O>((err, caught) => {
    let error = err;
    if (isApplicationError(err)) {
      error = err[0];
    } else if (err.errors) {
      error = Object.values(err.errors)[0] as string;
    }
    let res;
    if (handle) {
      res = handle(error, caught);
    }
    return res ?? EMPTY;
  });
}

export function onErrorWithMessage<T>() {
  return onError<T, Observable<T>>((err) => {
    console.error(err, inject(UiNotificationService));
    inject(UiNotificationService).error(err);
  });
}

export function completeLoading<T>(callback: () => void) {
  return function (source: Observable<T>) {
    return source.pipe(
      tap(
        () => callback(),
        () => callback(),
      ),
    );
  };
}
